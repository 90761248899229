<template>
    <div>
        <Loader :isLoading="isLoading" />
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>CATEGORIES</h2>
                        <p>Top Categories in MEPC</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="categorie_area">
            <div class="container">
                <div class="categorie_heading">
                    <h1>MEPC CATEGORIES</h1>
                    <ImageItem src="images/line.png" />
                    <p style="font-size: 18px">
                        Now post/view surplus pre-owned inventory here. One-stop solution to the
                        pre-owned construction material. Authenticate the material via listed
                        material inspectors Reduce your overheads.
                    </p>
                </div>
                <div class="row">
                    <div class="my-3 col-lg-3 col-md-6" v-for="user in category" :key="user.id">
                        <div
                            class="categorie_box d-flex justify-content-between flex-column pb-0"
                            style="border-top: 3px solid;"
                            v-bind:style="{ color: user.color }"
                        >
                            <div>
                                <div
                                    class="categorie_image"
                                    v-bind:style="{ background: user.color }"
                                >
                                    <ImageItem :src="imgsrc + user.img" />
                                </div>
                                <h4>{{ user.name }}</h4>

                                <div
                                    v-for="subcategoryname in subcategory"
                                    :key="subcategoryname.id"
                                >
                                    <div v-if="subcategoryname.parent_id == user.id">
                                        <p>
                                            {{ subcategoryname.name }}
                                            <span>(1)</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <router-link
                                class="nav-link post-btn"
                                :to="{
                                    name: 'product-listing',
                                    query: { category: user.id },
                                }"
                                ><i
                                    style="border-top: 1px solid;"
                                    v-bind:style="{ color: user.color }"
                                ></i
                                >VIEW ALL</router-link
                            >
                            <!--<a href="#" style="border-top: 1px solid;" v-bind:style="{color:user.color}">VIEW ALL</a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios"
import Loader from "./Loader.vue"
import scrollTop from "./utils/scrollTop"
import ImageItem from "./utils/ImageItem"

//import { HTTP } from "../_helper/http-constants";

//axios.defaults.withCredentials = true;

export default {
    name: "categories",
    components: { Loader, ImageItem },
    data() {
        return {
            // imgsrc: "http://103.212.120.205/~dev/public/admin_profile/",
            imgsrc: axios.defaults.uploadURL,
            //imgsrc: 'images/',

            category: null,
            subcategory: null,
            isLoading: false,
        }
    },

    async created() {
        this.isLoading = true
        try {
            let response = await axios.get("/api/categories")
            this.category = response.data

            response = await axios.get("/api/category")
            this.subcategory = response.data
        } catch (err) {
            console.log("Erorr in loadinng category information")
        }
        this.isLoading = false
    },
    async mounted() {
        scrollTop()
    },
    beforeDestroy() {
        this.isLoading = true
        console.log("categories unmounting")
    },
}
</script>
